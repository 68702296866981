.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imgRotate {
  animation: rotation 6s infinite linear;
  position: relative;
}

.fullbodyLoaderBgColor {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  height: 100vh;
}

.imgtextsctnmain > .container {
  /* border-bottom: 1px solid #f36e36; */
  padding-bottom: 60px;
}

.justifyContentCenter {
  justify-content: center;
}

.dflex {
  display: flex;
}

.alignItemCenter {
  align-items: center;
}

.vh100 {
  height: 100vh;
}

img.loading4icon_heart {
  position: absolute;
}

.vh100 {
  height: 100vh;
}

.fullbodyLoaderBgColor {
  background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
  height: 100vh;
}

.ftrText {
  position: absolute;
  bottom: 5%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  display: block;
  width: 100%;
  font-family: "Montserrat";
}

input[type="checkbox"] {
  accent-color: red;
}

.addressForm #progressbar li:nth-child(1) {
  margin-left: 46%;
}

.addressForm #progressbar::before {
  width: 50%;
}

.familyInfo #progressbar li:nth-child(1) {
  margin-left: 65%;
}

.familyInfo #progressbar::before {
  width: 66%;
}

.AdditionalInfo #progressbar li:nth-child(1) {
  margin-left: 97%;
}

.AdditionalInfo #progressbar::before {
  width: 99%;
}

.EducationWorkfrom #progressbar li:nth-child(1) {
  margin-left: 23%;
}

.EducationWorkfrom #progressbar::before {
  width: 25%;
}

/* 
SELECT {
  /* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.00072 5.17217L11.9507 0.222168L13.3647 1.63617L7.00072 8.00017L0.636719 1.63617L2.05072 0.222168L7.00072 5.17217Z' fill='#626262'/></svg>") no-repeat; */
/* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat; */

/* background: url("../src/assets/images/select_dropdown_icon.svg"); */
/* background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important; 
  appearance: none !important;
  padding-right: 2rem !important; 
} 
*/

.pdx40 {
  padding-right: 40px;
  padding-left: 40px;
}

.imgRotate {
  animation: rotation 6s infinite linear;
  position: relative;
}

img.loading4icon_heart {
  position: absolute;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login_form select.dropdown-menu {
  position: relative;
  width: 100%;

  color: #000;
  border-radius: 28px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}

.mailIcon i {
  font-size: 152px;
  color: #fff;
}

.ftrText a {
  color: #fff;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 700;
}

.ftrText a:hover {
  margin-left: 10px;
  transition: 0.5s ease;
}

.headerstylefLi {
  min-width: fit-content;
}

.headerstyleSLi {
  border-bottom: 2px solid #e02b71;
  min-width: 100%;
}

.headerstyle {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.headerstyle li p {
  margin: 0px;
  padding: 0px 40px 0px 0px;
  line-height: 0.5;
}

.headerstyle.rowReverse li p {
  margin: 0px;
  padding: 0px 0px 0px 40px;
  line-height: 0.5;
}

.pl45 {
  padding-left: 45px;
}

.fixed-content {
  top: 0;
  bottom: 0;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
}

.space-below {
  margin-bottom: 200px;
}
/* header css*/

/*-------- My Css -*************/



        /* Designing for scroll-bar */
        ::-webkit-scrollbar {
          width: 6px;
      }
  
      /* Track */
      ::-webkit-scrollbar-track {
          background: gainsboro;
          border-radius: 5px;
      }
  
      /* Handle */
      ::-webkit-scrollbar-thumb {
          background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%);
          border-radius: 5px;
      }
  
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
          background: #555;
      }


      .main-sidebar[class*="sidebar-dark-"] {
        background-color: #5b1d2d!important;
      }

      .brand-text{
        visibility: hidden!important;
      }

      .brand-link .brand-image {
        float: initial; 
        line-height: 0.8;
        margin-left: 0.8rem;
        margin-right: 0.5rem;
        margin-top: -3px;
        max-height: 33px;
        width: auto;
      }

      .sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active{
        background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%)!important;
      }

      .nav-pills .nav-link {
        border-radius: 0px;
      }

      .sidebar .user-panel{ }

      .user-panel .info {
        padding: 0px 5px 0px 10px!important;
        font-size: 22px!important;
        font-weight: 600!important;
        color: #FFF;
      }

      .btnLogout{
        background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%)!important;
        color:#fff!important;
      }

      .main-header .nav-link {
        height: 5rem!important;
      }

      [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview {
        background-color: #6c3442 !important;
        border-radius: 5px;
      }

    [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview .nav-item a{
        padding-left: 45px;
        border-bottom: 1px solid #ffffff21;
    }

    [class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview .nav-item a.active{
      color: #343a40!important;
  }

    [class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link{
      background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%)!important;
      color: #fff;
    }

    .card-primary:not(.card-outline) > .card-header {
      background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%)!important;
   }

   .sidebar a {
    color: #fff!important;
  }

   .btn-primary {
    color: #fff;
    background: linear-gradient(45deg, #dd2477 0%, #f36e36 100%)!important;
    border:none!important;
  }
  

 .ml250 {
    margin-left: 250px;
    margin-top: 45px;
}

.breadcrumb{
  display: none!important;
}

.main-footer a{
  color: #dd2477;
}

.profile-user-img {
  width: 150px!important;
  height: 150px!important;
}

.profileDocumentBlock .btn-info {
  background-color: #dd2477;
  border: none;
  padding: 10px 0px;
  width: 100px!important;
  margin-top: 10px;
  border-radius: 40px;
}

.profileDocumentBlock .btn-info:hover{
  background-color: #d4538d;
  border: none;
  padding: 10px 0px;
  width: 100px!important;
  margin-top: 10px;
  border-radius: 40px;
}

.profileDocumentBlock .btn-danger{
  background-color: #fff;
  border: none;
  color: #000;
  border:1px solid #d4d4d4;
  padding: 10px 0px;
  width: 100px!important;
  margin-top: 10px;
  border-radius: 40px;
}

.profileDocumentBlock .btn-danger:hover{
  background-color: #fff;
  color: #000;
}

body .pd0{
  padding:0px;
}

.post {
  margin-top: 40px;
}
.header-btns {
  display: flex;
  align-items: center;
  justify-content: end;
}
